import React from "react";
import IndexPage from "./components/bms-index/IndexPage";
import { AuthProvider } from "./components/bms-authentication/AuthContext";

function App() {
  return (
    <div>
        <AuthProvider>
          <IndexPage />
        </AuthProvider>
    </div>
  );
}

export default App;
