import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "../bms-login/LoginPage";
import UsersListPage from "../bms-userslist/UsersListPage";
import { Container } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import NotFound from "./NotFound";

const IndexPage = () => {
  return (
    <Router>
      <Header />
      <Container component="div" sx={{height:"700px"}}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/userslist" element={<UsersListPage />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
};

export default IndexPage;
