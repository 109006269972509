import React from 'react'

const NotFound = () => {
  return (
    <div style={{display:"flex",justifyContent:"center", alignContent:"center",alignItems:"center",marginTop:"200px",marginBottom:"200px"}}>
        <code style={{color:"red",fontSize:"17px"}}>
            Invalid Page Request, 404 Not Found..
        </code>
    </div>
  )
}

export default NotFound