import React from "react";
import axios from "axios";
import "./LoginPage.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Box,
  Paper,
  Grid,
  Avatar,
  CssBaseline,
  InputAdornment,
} from "@mui/material";
import AdminPanelSettingsSharpIcon from "@mui/icons-material/AdminPanelSettingsSharp";
import sha512 from "crypto-js/sha512";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../bms-authentication/AuthContext";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import LockSharpIcon from "@mui/icons-material/LockSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { purple } from "@mui/material/colors";

const LoginPage = () => {
  const { setApiToken, setUserId, state, setErrorMessage } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (values, props) => {
    const username = values.username;

    const hashedPassword = sha512(values.hash).toString();

    const requestBody = JSON.stringify({
      hash: hashedPassword,
    });

    const url = `https://api.grvcle.in/bms/v1/admin/${username}/login`;

    try {
      const response = await axios.post(url, requestBody);

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // console.log("LOGIN RESPONSE :", response.data);

      if (response.data.status === "ok") {
        setApiToken(response.data.data["api-token"]);
        setUserId(username);
        // localStorage.setItem("apiToken", response.data.data["api-token"]);
        // console.log(`LOGIN API-TOKEN ::` , response.data.data["api-token"]);
        navigate(`/userslist`);
      } else {
        const message = response.data.message.toUpperCase();
        setErrorMessage(message || "Invalid username or password");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred during login.");
    }

    setTimeout(() => {
      props.resetForm();
      props.setSubmitting(false);
    }, 2000);
  };

  //Form Validation By Using Formik And Yup
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    hash: Yup.string().required("Password is required"),
  });

  //Styles
  const paperStyle = {
    padding: 20,
    width: "309px",
    margin: "170px auto",
  };

  const AvatarStyle = {
    backgroundColor: "#29007f",
  };

  const IconStyle = {
    fontSize: "23px",
    color: "#29007f",
  };

  const divStyle = { margin: "10px 0px 10px 0px" };

  return (
    <div>
      <Paper elevation={12} style={paperStyle}>
        <CssBaseline />
        <div
          style={{
            color: "red",
            textAlign: "center",
            margin: "2px,0px,10px,0px",
          }}
        >
          <p>{state.errorMessage}</p>
        </div>
        <Grid align={"center"}>
          <Avatar style={AvatarStyle}>
            <AdminPanelSettingsSharpIcon sx={{ fontSize: "28px" }} />
          </Avatar>
          <Typography
            component="h3"
            variant="h4"
            display={"flex"}
            justifyContent={"center"}
            padding={"10px"}
            margin={"5px auto"}
            fontSize={"32px"}
            fontFamily={"revert-layer"}
            fontWeight={"bold"}
          >
            LOGIN
          </Typography>
        </Grid>
        <Box component="div">
          <Formik
            initialValues={{ username: "", hash: "", rememberMe: false }}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {(props) => (
              <Form>
                <div style={divStyle}>
                  <Field
                    margin="dense"
                    type="text"
                    name="username"
                    label="Username"
                    placeholder="Enter Username"
                    id="input-with-icon-textfield1"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonSharpIcon sx={IconStyle} />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    fullWidth
                    as={TextField}
                    color={props.values.username ? "secondary" : "error"}
                    helperText={<ErrorMessage name="username" />}
                  />
                </div>
                <div style={divStyle}>
                  <Field
                    margin="dense"
                    type="password"
                    name="hash"
                    label="Password"
                    placeholder="Enter Password"
                    id="input-with-icon-textfield2"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockSharpIcon sx={IconStyle} />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    color={props.values.hash ? "secondary" : "error"}
                    fullWidth
                    as={TextField}
                    helperText={<ErrorMessage name="hash" />}
                  />
                </div>
                <div style={divStyle}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="rememberMe"
                        sx={{
                          color: purple[900],
                          "&.Mui-checked": {
                            color: purple[600],
                          },
                        }}
                      />
                    }
                    label="Remember me"
                  />
                </div>
                <div style={divStyle}>
                  <Button
                    sx={{
                      margin: "8px 0",
                      fontSize: "19px",
                      backgroundColor: "#29007f",
                    }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    margin="dense"
                    disabled={props.isSubmitting}
                  >
                    {props.isSubmitting ? (
                      <CircularProgress size={22} sx={{ color: "black" }} />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </div>
  );
};

export default LoginPage;
