import React from "react";

const Footer = () => {
  return (
    <div style={{ textAlign: "center",position:"static",width:"100%"}}>
      <p style={{textAlign: "center",marginBottom:"40px"}}>&copy; 2023 GRVCLE. All rights reserved.</p>
    </div>
  );
};

export default Footer;
