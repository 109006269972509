import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import AdminPanelSettingsSharp from "@mui/icons-material/AdminPanelSettingsSharp";


const Header = () => {
  return (
    <div>
      <AppBar position="fixed" color="grey" sx={{marginBottom:"50px"}}>
        <Toolbar sx={{ display: "flex", justifyContent: "center",my:1 }}>
          <AdminPanelSettingsSharp sx={{fontSize:"40px"}} />
          <Typography
            variant="h4"
            component="h2"
            sx={{
              margin: "15px 0px 15px 0px",
              fontSize: "35px",
              fontFamily: "sans-serif",
              fontWeight:"bolder"
            }}
          >
            SUPER ADMIN
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
