import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../bms-authentication/AuthContext";
import PersonSharpIcon from "@mui/icons-material/PersonSharp";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Paper,
  ButtonGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

const UsersListPage = () => {
  const navigate = useNavigate();
  const { state, setApiToken } = useAuth();
  const [admin, setAdmin] = useState(null);
  const [errorMessage, setErrMsg] = useState("");
  const [adminName, setadminName] = useState("");
  const { apiToken, userId } = state;
  const [adminNotFound, setadminNotFound] = useState("");
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);

  // Code For Type Selector
  const [type, setType] = useState("");

  const handleChange = (event) => {
    setType(event.target.value);
    setAlertOpen(false);
  };

  const handleSuccessAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlertOpen(false);
  };

  // Code For Set Admin Type
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");

  const handleCloseAlert = (event, reason) => {
    if (reason !== "clickaway") {
      setAlertOpen(false);
    }
  };

  const handleSetAdminType = async () => {
    try {
      if (admin && type) {
        const accessToken = apiToken;
        const response = await axios.post(
          `https://api.grvcle.in/bms/v1/admin/user/${inputUserId}/user-type`,
          {
            type: type,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${userId}:${accessToken}`,
            },
          }
        );

        // Update the admin type in the local state
        setAdmin((prevAdmin) => ({
          ...prevAdmin,
          type: type,
        }));

        // Display Material UI Alert with response message
        setAlertOpen(false);
        setAlertSeverity("success");
        setAlertMessage(response.data.message.toUpperCase());
        setAlertOpen(true);
      } else {
        // Display Material UI Alert for missing type or admin
        setAlertSeverity("warning");
        setAlertMessage(
          "Please select a type and fetch admin details before setting the type"
        );
      }
    } catch (error) {
      console.error("Error setting admin type:", error);
      // Display Material UI Alert for error
      setAlertSeverity("error");
      setAlertMessage("An error occurred while setting admin type");
    } finally {
      // Ensure the alert is closed after handling the operation
      setAlertOpen(true);
    }
  };

  // Code For Get Details
  const [inputUserId, setInputUserId] = useState("");
  const [showAdminDetails, setShowAdminDetails] = useState(false);
  const [validUserId, setValidUserId] = useState(true);

  const handleInputChange = (event) => {
    setInputUserId(event.target.value);
    setShowAdminDetails(false);
    setValidUserId(true);
  };

  const handleGetDetails = async () => {
    if (inputUserId) {
      try {
        await fetchUserDetails(inputUserId);
        // setInputUserId("");
        setErrMsg("");
        setadminNotFound("");
        setShowAdminDetails(true);
        setValidUserId(true);
      } catch (error) {
        if (error.message === "Invalid user data") {
          setErrMsg("Invalid user data. Please enter a valid User ID");
        } else {
          setErrMsg("An error occurred while fetching user details");
        }

        setadminNotFound(" No Admins Found");
        setShowAdminDetails(false);
        setValidUserId(false);
      }
    } else {
      setadminNotFound("Invalid Id");
      setValidUserId(false);
    }
  };

  // Fetching User/Admin Details
  const fetchUserDetails = async (id) => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/user/${id}/details`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );
      if (response.data && response.data.data) {
        setAdmin(response.data.data);
      } else {
        throw new Error("Invalid user data");
      }
    } catch (err) {
      console.error("Error fetching user details:", err);
      setErrMsg("An error occurred while fetching user details");
      if (!err.response) {
        setErrMsg("No Server Response");
      } else if (err.response.status === 400) {
        setErrMsg("Not Found");
      } else if (err.response.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      throw err;
    }
  };

  // Fetching Current Admin Name
  const fetchUserName = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.get(
        `https://api.grvcle.in/bms/v1/admin/user/${userId}/details`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );
      setadminName(response.data.data.name);
    } catch (err) {
      console.error("Error fetching user details:", err);
      setErrMsg("An error occurred while fetching user details");
      if (!err.response) {
        setErrMsg("No Server Response");
      } else if (err.response.status === 400) {
        setErrMsg("Not Found");
      } else if (err.response.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  useEffect(() => {
    const tryAutoLogin = async () => {
      if (!apiToken) {
        // If there's no stored token, navigate to the login page
        navigate("/login");
      } else {
        // If there is a token, fetch user details and name
        await fetchUserDetails();
        await fetchUserName();
        setSuccessAlertOpen(true);
      }
    };

    tryAutoLogin();
}, [apiToken, userId, navigate]);

  

  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
  };

  // Code For Current Admin Logout
  //Logout Function
  const handleLogout = async () => {
    try {
      const accessToken = apiToken;
      const response = await axios.post(
        `https://api.grvcle.in/bms/v1/admin/logout`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${userId}:${accessToken}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setApiToken(null);
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  //Styles For Table Row
  const TblCellStyle = {
    fontFamily: "sans-serif",
    width: "300px",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder",
    fontSize: "15px",
  };

  const TblContent = { textAlign: "center", padding: 2, my: 4 };

  return (
    <Paper
      elevation={7}
      sx={{
        marginTop: "150px",
        minWidth: 500,
        padding: 6,
        paddingLeft: 8,
        paddingRight: 8,
        width: "75%",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "auto",
      }}
    >
      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleCloseAlert}
          severity={alertSeverity}
        >
          <AlertTitle>
            {alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}
          </AlertTitle>
          {alertMessage}
        </MuiAlert>
      </Snackbar>

      {/* Successful login message alert */}
      <Snackbar
        open={successAlertOpen}
        autoHideDuration={2500}
        onClose={handleSuccessAlertClose}
        sx={{
          position: "absolute",
          top: "50px",
          left: "auto",
          right: "auto",
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={7}
          variant="filled"
          onClose={handleSuccessAlertClose}
          severity="success"
        >
          <AlertTitle>
            Welcome <strong>{adminName.charAt(0).toUpperCase() + adminName.slice(1)}</strong>
          </AlertTitle>
          You have successfully logged in!
        </MuiAlert>
      </Snackbar>

      {/* Logout Dialog Box */}
      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hello! <strong>{adminName}</strong>,&nbsp;Are you sure you want to
            <strong style={{ color: "grey" }}> logout?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setLogoutDialogOpen(false);
              handleLogout();
            }}
            color="error"
            autoFocus
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <ButtonGroup>
            <Box
              sx={{ display: "flex", alignItems: "flex-end", width: "170px" }}
            >
              {/* <PersonPinCircleSharpIcon
                sx={{
                  m: "5px",
                  marginLeft: "0px",
                  color: "black",
                  fontSize: "30px",
                }}
              /> */}
              <TextField
                id="input-with-sx"
                variant="outlined"
                label="Enter User ID"
                value={inputUserId}
                placeholder="Enter User ID"
                onChange={handleInputChange}
                size="small"
                color={validUserId ? "secondary" : "error"} // Set color based on validation
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleGetDetails}
              sx={{ backgroundColor: "#292828" }}
            >
              <Typography>GET</Typography>
              <PersonPinIcon
                sx={{
                  p: "1px",
                  marginLeft: "3px",
                  color: "white",
                  fontSize: "22px",
                }}
              />
            </Button>
          </ButtonGroup>
          {!validUserId && !inputUserId && (
            <Typography
              variant="body1"
              color="error"
              sx={{ marginLeft: "10px" }}
            >
              Please enter the User ID
            </Typography>
          )}
          {!validUserId && inputUserId && (
            <Typography
              variant="body1"
              color="error"
              sx={{ marginLeft: "10px" }}
            >
              Invalid User ID
            </Typography>
          )}
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            sx={{ height: "45px" }}
          >
            <Button color="success" sx={{ backgroundColor: "#292828" }}>
              <PersonSharpIcon sx={{ fontSize: "22px", padding: "3px" }} />
              <Typography sx={{ fontSize: "15px" }}>{adminName}</Typography>
            </Button>
            <Button
              onClick={handleLogoutClick}
              variant="contained"
              color="error"
              sx={{ backgroundColor: "#292828" }}
            >
              <Typography sx={{ fontSize: "15px" }}>Logout</Typography>
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      {/* Table Box */}
      {admin && showAdminDetails && validUserId && (
        <Box
          component="div"
          sx={{
            width: "100%",
            marginTop: "15px",
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ display: admin.email ? "table-row" : "none" }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#292828" }}>
                  <TableCell sx={TblCellStyle}>Name</TableCell>
                  <TableCell sx={TblCellStyle}>Email</TableCell>
                  <TableCell sx={TblCellStyle}>Admin Type</TableCell>
                  <TableCell sx={TblCellStyle}>Set Admin Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    cursor: "pointer",
                    my: 4,
                    display: admin.email ? "table-row" : "none",
                  }}
                >
                  <TableCell sx={TblContent}>{admin.name}</TableCell>
                  <TableCell sx={TblContent}>{admin.email}</TableCell>
                  <TableCell sx={TblContent}>{admin.type}</TableCell>
                  <TableCell sx={TblContent}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl sx={{ m: 2, minWidth: 80 }}>
                        <InputLabel
                          id="demo-simple-select-autowidth-label"
                          autowidth="true"
                        >
                          Select Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={type}
                          onChange={handleChange}
                          label="Select Type"
                          size="small"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={"admin"}>ADMIN</MenuItem>
                          <MenuItem value={"site-admin"}>SITE ADMIN</MenuItem>
                          <MenuItem value={"user"}>USER</MenuItem>
                        </Select>
                      </FormControl>
                      <Button
                        variant="contained"
                        sx={{
                          height: "41px",
                          padding: 2,
                          backgroundColor: "#292828",
                        }}
                        onClick={handleSetAdminType}
                      >
                        SET
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Box>
        {!showAdminDetails && (
          <Typography
            variant="body1"
            color="error"
            sx={{ my: "20px", textAlign: "center" }}
          >
            {adminNotFound}
          </Typography>
        )}
        {errorMessage && (
          <Typography
            variant="body1"
            color="error"
            sx={{ my: "20px", textAlign: "center" }}
          >
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default UsersListPage;
